import { SnackbarProvider } from "notistack";
import { I18nextProvider } from "react-i18next";
import "./App.css";
import MainLayout from "./Container/MainLayout";
import { AppContextProvider } from "./utils/contextProvider/AppContext";
import i18n from "./utils/i18next/i18n";
import ThemeProvider from "./utils/themeProvider";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./utils/ErrorFallBack";
import * as Sentry from "@sentry/react";

function App() {
  try {
    Sentry.init({
      dsn: "https://fd49908f75b9959a71cb190706e52ea3@o4506744673861632.ingest.us.sentry.io/4507072805142528",
      integrations: [Sentry.replayIntegration()],
      replaysSessionSampleRate: 0.1,
      tracesSampleRate: 1.0,
      tracePropagationTargets: [/^https:\/\/housing\.flame\.edu\.in$/],
      replaysOnErrorSampleRate: 1.0,
    });
  } catch (err) {
    console.error("Sentry Initialization", err);
  }

  return (
    <BrowserRouter>
      <AppContextProvider>
        <SnackbarProvider>
          <I18nextProvider i18n={i18n}>
            <ThemeProvider>
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {
                  window.location.reload();
                }}
              >
                <MainLayout />
              </ErrorBoundary>
            </ThemeProvider>
          </I18nextProvider>
        </SnackbarProvider>
      </AppContextProvider>
    </BrowserRouter>
  );
}

export default App;
