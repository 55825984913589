export const RESTART_ON_REMOUNT = "@@saga-injector/restart-on-remount";
export const DAEMON = "@@saga-injector/daemon";
export const ONCE_TILL_UNMOUNT = "@@saga-injector/once-till-unmount";
export const KEY_PARENT_DETAILS = "PARENT_DETAILS";
export const KEY_USER_DETAILS = "userDetails";
export const KEY_LOGIN_ACCESS_TOKEN = "login_token";
export const KEY_COURSE = "Course";
export const REDIRECT_URL = "housing.flame.edu.in/residence-allocation";

export const REGEX = {
  NAME: /^[a-zA-Z ]*$/,
  MOBILE: /^(\+\d{1,3}[- ]?)?\d{10}$/,
  PINCODE: /^[0-9]{6,6}$/,
  NUMBER: /[^0-9]/,
  ONLYNUMBER: /^[0-9]*$/,
  NUMBERWITHDECIMAL: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
  ANNUALINCOME: /^(?!0)\d*(\.\d+)?$/,
  DIGITS_ONLY_UPTO_100: /^\-?(([0-9]\d?|0\d{1,2})((\.)\d{0,2})?|100((\.)0{1,2})?)$/,
  DIGITS_ONLY_UPTO_10: /^\-?((0\d|\d)?((\.)\d{0,2})?|10((\.)0{1,2})?)$/,
  DIGITS_ONLY_UPTO_4: /^\-?((0[0-3]|[0-3])?((\.)\d{0,2})?|(4|04)((\.)0{1,2})?)$/,
  PERCENTAGE: /^100$/,
  DECIMAL: /^(\d*\.)?\d+$/,
  ALPHANUMBERIC: /^[a-zA-Z0-9]+$/,
  CGPA: /^10$/,
  GST: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  PAN: /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
  GRADE: /^[a-fA-FOoPp]$/,
  MIXED: /^[a-zA-Z0-9-./, ]*$/,
  MIXED_ADDRESS: /^[a-zA-Z0-9-.#/, ]*$/,
  PARENT_DOC: /^aadhaarParent/,
  PHONE_NUMBER: /^[0-9]*$/,
  AADHAAR: "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$",
  AlphaSpecial: /^[A-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]*$/i,
  LINKEDIN: /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/([a-zA-Z0-9-]+)\/?/,
  TWITTER: /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/,
  CHEQUE: /^\d{6}$/,
  HTTPS: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
  EMAIL: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
  //   EMAIL:
  //     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
export const KEY_DIRECTION = {
  left: "left",
  right: "right",
  previous: "prev",
  forward: "forward",
  next: "next",
};
export const KEY_USER_TYPE = {
  parent: "Parent",
  faculty: "Faculty",
  student: "Student",
};
export const KEY_STATUS = {
  success: "success",
  failed: "failed",
  warning: "Warning",
};
export const KEY_LOGIN_VIEW = {
  full: "full",
};
export const GLOBAL_VALIDATION = {
  VALIDATIONS: {
    REQ: "Required",
    STATUS: {
      ERROR: "error",
      SUCCESS: "success",
    },
    TYPE: {
      STATUS: "status",
    },
  },
};
export const KEY_USER = {
  REGEX: {
    NAME: /^[a-zA-Z ]*$/,
    MOBILE: /^(\+\d{1,3}[- ]?)?\d{10}$/,
    NUMBER: /^[0-9]*$/,
    ALPHANUMERIC: /^[a-z0-9]+$/i,
    EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
  },
};

export const KEY_DATE_FORMAT = "YYYY-MM-DD";
export const KEY_TIME_FORMAT = "hh:mm A";
export const KEY_ACCESS_TOKEN = "access_token";
export const KEY_REFRESH_TOKEN = "refresh_token";
export const KEY_CONFIG_URL = "https://config-api.ken42.com/v1/configs/url";
export const REFRESH_URL = "https://flame-prod-admission-api.ken42.com/pfs-integration/api/refreshToken";
export const KEY_FIRE_REG_TOKEN = "rToken";

export const KEY_PORTAL_TYPE = {
  parent: "PARENT PORTAL",
  faculty: "FACULTY PORTAL",
  student: "STUDENT PORTAL",
};
export const KEY_POSITION = {
  bottom: "bottom",
  center_top: "center top",
  center_bottom: "center bottom",
  top: "top",
};

export const KEY_LOGIN_TYPE = {
  google: "google",
  msal: "msal",
};

export const KEY_SEVERITY = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
};

export const KEY_PERFORMANCE = {
  EXCELLENT: "excellent",
  GOOD: "good",
  FAIR: "fair",
  POOR: "poor",
  BAD: "bad",
};

export const KEY_ATTENDANCE_PROGRESS_VALUES = {
  MIN: "30",
  MAX: "100",
  RANGE_MIN: "30",
  RANGE_MAX: "85",
  RANGE_AVERAGE: "85",
};

export const KEY_USER_CHANGE_LANG = "userChangeLang";

export const KEY_CBSE_DISTRIBUTION = {
  subject: "SUBJECT",
  pp: "P & P",
  ma: "MA",
  portfolio: "PORTFOLIO",
  subEn: "SUB EN",
  tMarks: "TOTAL MARKS",
  grade: "GRADE",
};

export const KEY_SIGNATURE = {
  PARENT: "parent_signature.jpg",
  CLASS_TEACHER: "teacher_signature.jpg",
  PRIMARY_HEAD: "head_signature.jpg",
  ALLOWED_FILE_TYPES: ["image/svg", "image/png", "image/jpeg"],
  MAX_FILE_SIZE: 1000000,
};

export const KEY_EMPTY_VALUES_PLACEHOLDERS = {
  DOUBLE_DASH: "--",
  SINGLE_DASH: "-",
  NA: "NA",
};

export const KEY_ROLES = {
  CLASS_TEACHER: "Class Teacher",
};

export const KEY_FACULTY_PROFILE_IMAGE = {
  TYPE: "faculty_profile_image.jpg",
  ALLOWED_FILE_TYPES: ["image/svg", "image/png", "image/jpeg"],
  MAX_FILE_SIZE: 307200,
};

export const TRANSACTIONS = {
  CREATE: "CREATE",
  RETRIEVE: "RETRIEVE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const QUESTION_TYPES = {
  MULTIPLE_CHOICE: "multichoice",
  TRUE_FALSE: "truefalse",
  NUMERICAL: "numerical",
  SUBJECTIVE: "essay",
  ESSAY: "essay",
  SHORT_ANSWER: "shortanswer",
  MATCH: "match",
  FILLTHEBLANKS: "gapselect",
};

export const SUBMISSION_STATUS = {
  SUBMITTED_LOCKED: "submitted-locked",
  NOT_SUBMITTED_LOCKED: "notSubmitted-locked",
  SUBMITTED_GRADED: "submitted-graded",
  SUBMITTED_OVERDUE_EXTENSION_GRANTED_EXTENSION_OVERDUE: "submitted-overdue-extensionGranted-extensionOverdue",
  SUBMITTED_OVERDUE_EXTENSION_GRANTED: "submitted-overdue-extensionGranted",
  SUBMITTED_OVERDUE_NO_EXTENSION_GRANTED: "submitted-overdue-noExtensionGranted",
  SUBMITTED_NOT_GRADED_NOT_OVERDUE: "submitted-noGrade-noOverDue",
  NOT_SUBMITTED_OVERDUE_EXTENSION_GRANTED_EXTENSION_OVERDUE: "notSubmitted-overdue-extensionGranted-extensionOverdue",
  NOT_SUBMITTED_OVERDUE_EXTENSION_GRANTED: "notSubmitted-overdue-extensionGranted",
  NOT_SUBMITTED_OVERDUE_NO_EXTENSION_GRANTED: "notSubmitted-overdue-noExtensionGranted",
  NOT_SUBMITTED_NO_OVERDUE: "notSubmitted-noOverDue",
};

export const TABLET_BREAKPOINT = "tablet";
