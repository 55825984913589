import { Button, Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { useHistory, useLocation } from "react-router-dom";
import KenLoader from "../../../../components/KenLoader";
import KenDialog from "../../../../global_components/KenDialogBox";
import { getProvisionalAllotmentDetails } from "../../../../utils/ApiService";
import Accommodation from "../Request-Application/Accommodation/Accommodation";
import DirectPay from "./Payments/DirectPay";
import PayMethod from "./Payments/Payments";

const useStyles = makeStyles((theme) => ({
  header: {
    color: "#222222",
    fontSize: "20px",
    fontFamily: "Lato",
    fontWeight: 600,
    lineHeight: "22.5px",
    wordWrap: "break-word",
    textAlign: "left",
  },
  header2: {
    color: "#222222",
    fontSize: "16px",
    fontFamily: "Lato",
    fontWeight: 600,
    lineHeight: "22.5px",
    wordWrap: "break-word",
    textAlign: "left",
  },
  box: {
    boxShadow: "none",
    borderRadius: 8,
    padding: "25px",
    minHeight: "85vh",
    position: "relative",
  },
  closeICO: {
    float: "right",
    fontSize: 30,
    cursor: "pointer",
  },
  description: {
    textAlign: "left",
    lineHeight: "22.5px",
    fontSize: "14px",
  },
  closeButtonDiv: {
    justifyContent: "right",
    position: "absolute",
    bottom: "20px",
    right: "20px",
  },
  boxContainer: {
    marginTop: theme.spacing(3),
  },
  switchText: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22.5px",
  },
  switchText2: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22.5px",
  },
  switchCase: {
    width: "100%",
  },
  medicalFont: {
    display: "flex",
    gap: 30,
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
  dialog: {
    "& .MuiDialog-paperFullWidth": {
      padding: "16px",
      minHeight: "40vh",
      borderRadius: "20px",
      boxShadow: "none",
    },
    "& .MuiDialogContent-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiButton-containedPrimary": {
      textTransform: "capitalize !important",
    },
    "& .MuiButton-outlinedPrimary": {
      textTransform: "capitalize !important",
      border: "none",
    },
  },
  dialogHeader: {
    color: "#222",
    fontSize: "24px",
    fontWeight: "600",
  },
  preferenceHeader: {
    color: "#222",
    fontSize: "14px",
    fontWeight: "500",
  },
  preferenceBody: {
    color: "#08466F",
    fontSize: "20px",
    marginBottom: 8,
    fontWeight: 600,
  },
  dialogBody: {
    marginTop: 40,
    fontSize: "16px",
  },
  statusImage: {
    width: "60px",
  },
  preferenceBtn: {
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  fetchingInfo: {
    fontSize: "16px",
    fontWeight: 600,
    marginLeft: "8px",
    lineHeight: "22.5px",
  },
}));

const ProvisionalAllotment = (props) => {
  const {
    handleClose,
    handlePaymentComplete,
    currentFormStep,
    generalAllotment,
    user,
    setHaveData,
  } = props;
  const history = useHistory();
  const CONTACT_ID = user.ContactId;
  // const location = useLocation();
  const [fetchingInfo, setFetchingInfo] = useState("Fetching...");
  const [disablePay, setDisablePay] = useState(false);
  const [data, setData] = useState([]);
  const classes = useStyles();
  // const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const [termData, setTermData] = useState([]);
  const [currentFormData, setCurrentFormData] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedPanel, setSelectedPanel] = useState("panel1");
  const [amount, setAmount] = useState(0);
  const [isTypeA, setIsTypeA] = useState(false);
  const [transactionData, setTransactionData] = useState({
    id: null,
    date: null,
    amount: null,
    fileName: null,
  });
  const [previewMode, setPreviewMode] = useState(
    currentFormStep.order === 3 ? false : true
  );

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleSubmit = () => {
    if (selectedPanel === "panel2") {
      setDialogOpen(false);
      setOpen(true);
    } else if (selectedPanel === "panel1") {
      handlePay();
    }
  };
  const handleCloseDirect = () => {
    setOpen(false);
    setDialogOpen(true);
  };
  const handleSubmit2 = (data) => {
    console.log(data, "FormData");
    setHideButton(true);
    setTimeout(() => {
      setOpen(false);
      handlePaymentComplete();
    }, 2000);
  };
  const handlePay = () => {
    setDialogOpen(false);
    handlePaymentComplete();
  };

  useEffect(() => {
    setLoading(true);
    getProvisionalAllotmentDetails(CONTACT_ID)
      .then((res) => {
        console.log(res, "res");
        const data = [];
        setCurrentFormData(res?.data);
        if (res?.data) {
          setHaveData(true);
        }
        setTermData(res?.myTerm);
        res?.data?.forEach((items, index) => {
          const lists =
            items?.Room__r?.Room_Category__r?.Description__c?.split(";") || [];
          const hasKeyword = (keyword) =>
            lists.some((text) =>
              text.toLowerCase().includes(keyword.toLowerCase())
            );
          const icons = [];
          if (hasKeyword("Non-air conditioned")) {
            icons.push("non-ac");
          }
          if (hasKeyword("Air-conditioned")) {
            icons.push("ac");
          }
          if (hasKeyword("twin-sharing")) {
            icons.push("twin-sharing");
          }
          if (hasKeyword("Flat Style")) {
            icons.push("flat-style");
          }
          if (hasKeyword("Standalone Room accommodation")) {
            icons.push("single-room");
          }
          setIsTypeA(
            (items?.Room__r?.Room_Category__r?.Name__c || "") ===
              "Type A Residence"
          );
          setAmount(items?.Fee_Assignments__r?.records[0]?.Total_Amount__c);
          data.push({
            ...items,
            Name: items?.Name,
            Name__c: items?.Room__r?.Room_Category__r?.Name__c || "-",
            Description__c: lists,
            Total_Price__c:
              (items?.Fee_Assignments__r?.records[0]?.Total_Amount__c || "") ===
              "Type A Residence"
                ? 0
                : items?.Fee_Assignments__r?.records[0]?.Total_Amount__c,
            id: index,
            icons: icons,
            preference: null,
          });
        });
        if (data.length === 0) {
          setFetchingInfo("No Data");
          setDisablePay(true);
        }
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  const handleRadioChange = (event) => {
    setSelectedPanel(event.target.value);
  };
  const handleGoBack = () => {};
  return (
    <div style={{ width: "100%" }}>
      {currentFormData && (
        <>
          {loading && <KenLoader />}
          <Grid container xs={12}>
            <Grid item component="main" xs={12} style={{ marginTop: "20px" }}>
              <Paper className={classes.box}>
                <Grid item xs={12}>
                  {generalAllotment ? (
                    <Typography variant="p" className={classes.header}>
                      General Allocation{" "}
                    </Typography>
                  ) : (
                    <Typography variant="p" className={classes.header}>
                      Provisional Allocation{" "}
                    </Typography>
                  )}
                  <IoIosClose
                    className={classes.closeICO}
                    onClick={handleClose}
                  />
                </Grid>
                <Grid item xs={12}>
                  {generalAllotment ? (
                    <Typography variant="p" className={classes.description}>
                      Based on the general allocation, here is the room
                      allocated to you:{" "}
                    </Typography>
                  ) : (
                    <Typography variant="p" className={classes.description}>
                      Based on the preferences sent in the Residence Request
                      form, your Provisional Allocation is as follows:{" "}
                    </Typography>
                  )}
                  <Grid variant="div" className={classes.boxContainer}>
                    <Grid container xs={12} lg={12} spacing={2}>
                      {data && data.length > 0 ? (
                        data.map((items, index) => {
                          console.log(items, "76u67u6");
                          return (
                            // <Grid item lg={3} md={4} sm={6} xs={12}>
                            //   <Accommodation termData={termData} item={items} key={index} disabled={previewMode} />
                            // </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                              <Accommodation
                                termData={termData}
                                item={items}
                                key={index}
                                disabled={previewMode}
                              />
                              {/* {items?.Fee_Assignments__r?.records.length > 0 && (
    <>
    <Typography variant="body1" style={{marginTop: "12px"}}>
    <b>Disclaimer</b>
    </Typography>
      <Typography variant="body1" style={{marginTop: "12px"}}>
      This is your Provisional Allocation. The payment gateway will be enabled shortly and you will be notified in advance for making the payment.
      </Typography>
      </>
      )} */}
                            </Grid>
                          );
                        })
                      ) : (
                        <Typography className={classes.fetchingInfo}>
                          {fetchingInfo}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container xs={12} className={classes.closeButtonDiv}>
                    <Button
                      color="primary"
                      variant="secondary"
                      style={{
                        textTransform: "capitalize",
                        marginRight: "10px",
                        maxHeight: "35px",
                        width: 85,
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    {!isTypeA && (
                      <Button
                        variant="contained"
                        color="primary"
                        // disabled={true}
                        style={{
                          textTransform: "capitalize",
                          maxHeight: "35px",
                          width: 130,
                        }}
                        onClick={() => {
                          handlePaymentComplete(currentFormData);
                        }}
                        // onClick={submitRequest}
                      >
                        Proceed to Pay
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {/* Dialog */}
            <KenDialog
              open={dialogOpen}
              dialogStyle={classes.dialog}
              handleClose={() => handleDialogClose()}
              negativeButtonClick={() => handleDialogClose()}
              positiveButtonClick={() => handleSubmit()}
              positiveButtonText="Confirm"
              negativeButtonText="Go Back"
            >
              <Typography
                style={{
                  display: "flex",
                  fontWeight: "600",
                  fontSize: "20px",
                  marginTop: "20px",
                }}
              >
                Payment Method
              </Typography>
              <PayMethod
                onGoBack={handleGoBack}
                selectedPanel={selectedPanel}
                setSelectedPanel={setSelectedPanel}
                handleRadioChange={handleRadioChange}
              />
            </KenDialog>
            {/* Dialog 2*/}
            <KenDialog
              open={open}
              dialogStyle={classes.dialog}
              handleClose={() => handleCloseDirect()}
              hidePositiveButton={true}
              hideNegativeButton={true}
            >
              {!hideButton ? (
                <DirectPay
                  transactionData={transactionData}
                  setTransactionData={setTransactionData}
                  handleCloseDirect={handleCloseDirect}
                  onSubmit={handleSubmit2}
                />
              ) : (
                <Grid xs={12}>
                  <img
                    src={
                      "https://inazstgpfs3001.blob.core.windows.net/assets/flame/Vector.svg"
                    }
                    alt="Submitted"
                    className={classes.statusImage}
                  />
                  <Typography
                    className={classes.dialogHeader}
                    style={{ marginTop: "20px" }}
                  >
                    Your payment is made successfully.{" "}
                  </Typography>
                </Grid>
              )}
            </KenDialog>
          </Grid>
        </>
      )}
    </div>
  );
};
export default ProvisionalAllotment;
