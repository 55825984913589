import { Box, Typography } from '@material-ui/core';
import React from 'react';
import KenButton from '../global_components/KenButton';

export function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <Box
      role="alert"
      alignItems="center"
      justifyContent="center"
      display="flex"
      flexDirection="column"
    >
      <Typography style={{ marginTop: 10 }}>Something went wrong</Typography>
      <KenButton onClick={resetErrorBoundary}>Click here to Refresh</KenButton>
    </Box>
  );
}
