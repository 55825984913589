import React, { useEffect, useState } from "react";
import { Box, Grid, ListItem, List, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import KenSelect from "../../../../../global_components/KenSelect";
import { useFormContext } from "react-hook-form";
import KenError from "../../../../../components/KenError";

const useStyles = makeStyles((theme) => ({
  box: {
    boxShadow: "none",
    borderRadius: 8,
    padding: "20px",
  },
  description2: {
    textAlign: "left",
    fontSize: "16px",
    color: "#000",
    fontWeight: 400,
  },
  accommodationBox: {
    border: "0.50px #E4E4E4 solid",
    padding: "20px",
    borderRadius: "8px",
    width: "100%",
    // maxHeight: '320px',
    minHeight: "388px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  accommodationType: {
    color: "#08466F",
    fontSize: "20px",
    fontWeight: 600,
  },
  icon: {
    width: "24px",
    marginRight: 4,
  },
  amount: {
    color: "#08466F",
    fontSize: "16px",
    fontWeight: 600,
  },
  descriptionList: {
    // height: '100px',
    // overflow: 'auto',
    marginTop: "20px",
  },
}));

const Accommodation = (props) => {
  const { item, termData, handleChangePreference, disabled, preferenceOptions } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext();
  const newTermData = (termData || []).filter((item) => item?.rio_ed__Status__c === "Current");
  return (
    <Box className={classes.accommodationBox}>
      <div>
        {item?.Name__c && (
          <Grid item xs={12}>
            <Typography variant="p" className={classes.accommodationType}>
              {item.Name__c}
            </Typography>
          </Grid>
        )}
        {item.Description__c?.length > 0 && (
          <Grid item xs={12} className={`${classes.descriptionList} scrollbar-cus`}>
            <ul>
              {item.Description__c?.map((items, index) => {
                return (
                  <li key={index} style={{ fontSize: 16, lineHeight: "22.5px" }}>
                    {items}
                  </li>
                );
              })}
            </ul>
          </Grid>
        )}
        {item?.amenities && (
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Typography variant="p" className={classes.description2}>
              {item.amenities}
            </Typography>
          </Grid>
        )}
      </div>
      <div>
        {item.preference !== null ? (
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <KenSelect
              id="term-select"
              options={preferenceOptions}
              disabled={item?.disabled == true ? true : false}
              value={item.preference}
              placeholder="Select Preference"
              label="Preference"
              style={{
                marginTop: "20px",
                fontSize: "14px !important",
                fontWeight: 600,
                color: "#777777 !important",
                textTransform: "uppercase",
              }}
              fullWidth
              className={classes.preference}
              {...register(`preference${item?.id + 1}`, {
                required: "Field is required",
                onChange: (e) => {
                  handleChangePreference(e, item?.id);
                },
              })}
            />
            {errors[`preference${item?.id + 1}`] && <KenError single={true} message={errors[`preference${item?.id + 1}`]?.message} />}
          </Grid>
        ) : null}
        {/* {item?.Total_Price__c && ( */}
        <Grid
          item
          xs={12}
          style={{
            margin: "20px 0px 0px 0px",
            bottom: "0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            {item?.icons &&
              item.icons.map((ico) => {
                let iconView;
                switch (ico) {
                  case "non-ac":
                    iconView = "https://inazstgpfs3001.blob.core.windows.net/assets/flame/fan.svg";
                    break;
                  case "ac":
                    iconView = "https://inazstgpfs3001.blob.core.windows.net/assets/flame/Air%20conditioner%20icon%201.png";
                    break;
                  case "twin-sharing":
                    iconView = `https://inazstgpfs3001.blob.core.windows.net/assets/flame/Two%20beds%20icon%201.png`;
                    break;
                  // case 'attached-bathroom':
                  //   iconView = `https://inazstgpfs3001.blob.core.windows.net/assets/flame/material-symbols_shower.svg`;
                  //   break;
                  // case 'flat-style':
                  //   iconView = `https://inazstgpfs3001.blob.core.windows.net/assets/flame/apartment.svg`;
                  //   break;
                  // case 'single-room':
                  //   iconView = `https://inazstgpfs3001.blob.core.windows.net/assets/flame/room.svg`;
                  //   break;
                  default:
                    iconView = null;
                }
                return <img src={iconView} className={classes.icon} />;
              })}
          </div>
          <Typography variant="p" className={classes.amount}>
            {t(`content:INR`)} &nbsp;
            {item?.Total_Price__c || 0}
            {/* {newTermData.length > 0 ? (newTermData[0].rio_ed__My_Term__c === 2 ? item?.Total_Price__c || 0 : newTermData[0].rio_ed__My_Term__c === 5 ? item?.Total_Price__c || 0 : "N/A") : item?.Total_Price__c || 0} */}
          </Typography>
        </Grid>
        {/* )} */}
      </div>
    </Box>
  );
};

export default Accommodation;
