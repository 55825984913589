import {
  Box,
  Button,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import KenLoader from "../../components/KenLoader";
import {
  getApplications,
  getCurrentStage,
  getEligibilityDetails,
  hdfcPaymentIntegartion,
} from "../../utils/ApiService";
import ProvisionalAllotment from "./Components/Provisional-Allotment";
import RequestApplication from "./Components/Request-Application";
import ResidenceAllocationClosed from "./Components/Residence-Allocation-Closed";
import ResidenceAllocationHistory from "./Components/Residence-Allocation-History";
import logo from "../../assets/logo.png";
import { useHistory, useLocation } from "react-router-dom";
import { REDIRECT_URL } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  header: {
    color: "#222222",
    fontSize: "20px",
    fontFamily: "Lato",
    fontWeight: 700,
    lineHeight: "22.5px",
    wordWrap: "break-word",
    margin: "0 auto",
  },
  box: {
    boxShadow: "none",
    borderRadius: 8,
  },
  description: {
    textAlign: "left",
    lineHeight: "22.5px",
    fontWeight: 500,
    fontSize: "16px",
    width: "100%",
  },
  deadline: {
    textAlign: "left",
    fontSize: "16px",
    fontWeight: 500,
  },
  formButton: {
    float: "left",
    textTransform: "capitalize",
  },
  imageBackground: {
    textAlign: "-webkit-center",
    backgroundImage: `url('https://inazstgpfs3001.blob.core.windows.net/assets/flame/background.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  stepperList: {
    textAlign: "left",
  },
  stepperLabel: {
    color: "#222",
    fontSize: "16px",
  },
}));
const formContainer = [
  {
    order: 1,
    title: "Welcome to MyResidences",
    info: [
      "You can submit your preferences for Type of Residence and Roommate for the upcoming academic year (2024-2025) through the Residence Request Form.",
      `The Residence Request Form is now open and the form will close on`,
      `Submission of preferences through the Residence Request Form is not a confirmation of the allocation of that Residence Type.`,
      `Kindly read the Residence Allocation Guidelines and FAQs here. Please ensure you are filling the Residence Request Form as per the Guidelines. For more information and queries, you may also send an email on support@flame.edu.in.`,
    ],
    show: false,
    points: false,
    deadline: false,
    date: "",
    button: "Go to Form",
  },
  {
    order: 2,
    title:
      "Your Residence Request Form for Academic Year 2024-2025 has been received and is under review.",
    info: [
      "Announcement of Your Provisional Allocation of Residence Type",
      "Payment of Residence Fees Before Deadline",
      "Announcement of Your Confirmed Allocation of Residence Type",
    ],
    subText: [
      "Kindly acquaint yourselves with the Guidelines (hyperlinked). If you have any queries, please reach out to accommodation@flame.edu.in.",
      "Dates may be subject to changes and updates which you will be notified of by email.",
    ],
    show: false,
    points: true,
    deadline: false,
    date: "",
    button: "View",
  },
  {
    order: 3,
    title: "A Provisional Allocation For Your Residence Type Has Been Made",
    info: [
      "We have provisionally allocated Residence Type in accordance with the prefrences submitted in the Residence Request Form. To convert this Provisional Allocation to Confirmed Allocation, you will be notified soon for the payment of applicable additional lodging charges.",
    ],
    show: false,
    points: false,
    deadline: false,
    date: null,
    button: "View",
  },
  {
    order: 4,
    title: "The Residence Request Application Form is now closed",
    info: [
      "You must submit your preferences for your preferred room 'Type' for academic year 2024-25 through the Residence Request Form. It is mandatory for all students to submit their preferences before the deadline. Please note that the RRF only captures your preferences and is not (italics) your confirmed allocation",
    ],
    show: false,
    points: false,
    deadline: false,
    subText: [
      "You can read the Revised Residence Allocation Policy here and other FAQs here.",
    ],
    date: "Residence Request Form will close at 12:00 midnight, 25th Jan 2024",
    button: "Open",
  },
  {
    order: 5,
    title: "General Pool Residence Allocation",
    info: ["You have been allocated a room from the General Pool"],
    show: false,
    points: false,
    deadline: false,
    date: null,
    button: "View",
  },
];

const ResidenceAllocation = (props) => {
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const classes = useStyles();
  const history = useHistory();
  // const { t } = useTranslation();
  const [haveData, setHaveData] = useState(false);
  const [currentStep, setCurrentStep] = useState();
  const CONTACT_ID = user.ContactId;
  const [checkEligibility, setEligibility] = useState();
  const [payStatus, setPayStatus] = useState(false);
  const [data, setData] = useState();
  const [currentData, setCurrentData] = useState();
  const [preferenceOptions, setpreferenceOptions] = useState([
    { value: "Preference 1", label: "Preference 1", disabled: false, index: 0 },
    { value: "Preference 2", label: "Preference 2", disabled: false, index: 1 },
    { value: "Preference 3", label: "Preference 3", disabled: false, index: 2 },
  ]);
  const [loading, setLoading] = useState(false);
  const [updateState, setUpdateState] = useState(0);
  const [termData, setTermData] = useState();
  const [showPending, setShowPending] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [currentStepData, setCurrentStepData] = useState();
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [isGeneralAllotmentBefore, setIsGeneralAllotmentBefore] =
    useState(false);
  const [currentScreen, setCurrentScreen] = useState({
    showBase: true,
    showRequestApplication: false,
    showProvisionalAllotment: false,
    showResidenceAllocation: false, // This step is for after payment is complete, So for step 3 and 5
    showResidenceAllocationClosed: false,
    showGeneralAllotment: false,
  });
  const [currentFormStep, setCurrentFormStep] = useState();

  const method = useForm({
    mode: "onChange",
  });
  const { getValues, setValue } = method;

  const handleStepComplete = () => {
    setCurrentStep(currentStep + 1);
  };
  const handleClose = () => {
    setUpdateState(updateState + 1);
    setCurrentScreen({ showBase: true, showRequestApplication: false });
  };
  const handleClose2 = () => {
    setUpdateState(updateState + 1);
    setCurrentScreen({ showBase: true, showProvisionalAllotment: false });
  };
  const handleClosePay = (data) => {
    let totalAmount = 0;
    let feeId = null;
    if (data?.length > 0) {
      data[0]?.Fee_Assignments__r?.records?.forEach((items) => {
        totalAmount = items?.Total_Amount__c + totalAmount;
        feeId = items?.Id;
      });
    }
    const payload = {
      amount: totalAmount,
      currencyCode: "INR",
      firstName: user?.Name,
      feeAssignment: feeId,
      // applicationId: user?.ContactId,
      paymentMethod: "NEFT/IMPS",
      email: user?.mail,
      // Notes: [...notes],
      redirectUrl: REDIRECT_URL,
    };
    hdfcPaymentIntegartion(payload)
      .then((res) => {
        console.log(res);
        document.write(res?.data);
        setTimeout(() => {
          handleClose3();
        }, 3000);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleClose3 = () => {
    setUpdateState(updateState + 1);
    setCurrentScreen({ showBase: true, showResidenceAllocationClosed: false });
  };
  // console.log('51572572',currentFormStep.order,payStatus)
  const handleShowScreen = () => {
    setCurrentScreen({
      showBase: false,
      showRequestApplication: currentFormStep.order === 1 ? true : false,
      showProvisionalAllotment:
        currentFormStep.order === 3 && !payStatus ? true : false,
      // This step is for after payment is complete, So for step 3 and 5
      showResidenceAllocation:
        currentFormStep.order === 4 && showHistory === true ? true : false,
      showResidenceAllocationClosed: currentFormStep.order === 4 ? true : false,
      showGeneralAllotment:
        currentFormStep.order === 4 && showPending === true ? true : false,
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      if (CONTACT_ID) {
        setLoading(true);
        try {
          const res = await getEligibilityDetails(CONTACT_ID);
          const { roomEligibility } = res;
          if (roomEligibility) {
            setEligibility(true);
          } else {
            setEligibility(false);
            setCurrentStep(0);
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.error("Error fetching eligibility details:", err);
        }
      }
    };
    fetchData();
  }, []);
  // Current Stages API
  useEffect(() => {
    if (checkEligibility) {
      setLoading(true);
      getCurrentStage(CONTACT_ID)
        .then((res) => {
          setLoading(false);
          if (res?.paymentStatus === "Pending") {
            setShowPending(true);
          } else if (res?.paymentStatus === "Paid") {
            setShowHistory(true);
          }
          setPaymentStatus(res?.paymentStatus === "Pending");
          // To Get the description data in card
          const formData = formContainer.find(
            (step) => step.order === res?.step
          );
          const isSameorAfter = moment(res.dates.Request_End_Date__c)
            .startOf("day")
            .isSameOrAfter(moment(new Date()).startOf("day"));
          const isSameorAfterGenral = moment(
            res?.dates?.General_Allotment_Announcement_Date__c
          )
            .startOf("day")
            .isSameOrBefore(moment(new Date()).startOf("day"));
          const isAfter = moment(res.dates.Last_Date_To_Make_Payment__c)
            .startOf("day")
            .isSameOrAfter(moment(new Date()).startOf("day"));
          setIsGeneralAllotmentBefore(isSameorAfterGenral);
          if (res.step === 1) {
            let info = [];
            if (!isSameorAfter) {
              formData.title =
                "The Residence Request Application Form is now closed";
              info = [
                <Typography>
                  Students will not be able to select their room preferences.
                  Please wait until the general allotment list is announced.
                </Typography>,
                <Typography>
                  You can read the revised residence allocation policy&nbsp;
                  <a
                    rel="noreferrer"
                    href={res?.dates?.Guidelines_URL__c}
                    target="_blank"
                  >
                    here
                  </a>
                  &nbsp;and other FAQs&nbsp;
                  <a
                    rel="noreferrer"
                    href="https://inazstgpfs3001.blob.core.windows.net/assets/flame/pdf/FAQ'S%20for%20Student%20Residence%20Allocation%20AY%202024-25%20.pdf"
                    target="_blank"
                  >
                    here.
                  </a>
                </Typography>,
              ];
              formData.button = null;
            } else {
              info = [
                "You can submit your preferences for Type of Residence and Roommate for the upcoming academic year (2024-2025) through the Residence Request Form.",
                `The Residence Request Form is now open and the form will close on ${moment(
                  res.dates.Request_End_Date__c
                ).format("Do MMM YYYY")}.`,
                <Typography>
                  Submission of preferences through the Residence Request Form
                  is
                  <b>
                    {" "}
                    <u>not</u>
                  </b>{" "}
                  a confirmation of the allocation of that Residence Type.
                </Typography>,
                <Typography>
                  Please note that once you have submitted the Residence Request
                  form you will not be permitted to make any changes to the
                  preferences and information submitted.
                </Typography>,
                <Typography>
                  Kindly read the{" "}
                  <a
                    rel="noreferrer"
                    href={res?.dates?.Guidelines_URL__c}
                    target="_blank"
                  >
                    Residence Allocation Guidelines
                  </a>{" "}
                  and{" "}
                  <a
                    rel="noreferrer"
                    href={res?.dates?.Guidelines_URL__c}
                    target="_blank"
                  >
                    FAQs
                  </a>
                  . Please ensure you are filling the Residence Request Form as
                  per the Guidelines. For more information and queries, you may
                  also send an email on accommodation@flame.edu.in.
                </Typography>,
              ];
            }
            formData.info = info;
          } else if (res.step === 2) {
            let info = [
              `Announcement of Your Provisional Allocation of Residence Type by ${moment(
                res.dates.Provisional_Allotment_Announcement_Date__c
              ).format("Do MMMM YYYY")}`,
              `Payment of Additional Lodging Fees/Charges (applicable for those who receive a Provisional Allocation of Type B or Type C Residences) by ${moment(
                res.dates.Last_Date_To_Make_Payment__c
              ).format("Do MMMM YYYY")}`,
              `Announcement of Confirmed Allocation Type by ${moment(
                res.dates.General_Allotment_Announcement_Date__c
              ).format("Do MMMM YYYY")}`,
            ];
            let subText = [
              <Typography>
                Kindly acquaint yourselves with the{" "}
                <a
                  rel="noreferrer"
                  href={res?.dates?.Guidelines_URL__c}
                  target="_blank"
                  style={{ fontWeight: 600 }}
                >
                  Guidelines
                </a>
                . If you have any queries, please reach out to
                accommodation@flame.edu.in.
              </Typography>,
              <Typography style={{ fontSize: "14px" }}>
                Dates may be subject to changes and updates which you will be
                notified of by email.
              </Typography>,
            ];
            formData.subText = subText;
            formData.info = info;
          } else if (
            res.step === 3 &&
            res?.paymentStatus === "Pending" &&
            !isAfter
          ) {
            let title = "The Provisional Allotment window has been closed";
            let info = [
              <Typography>
                Students will not be able to make the payment for the
                provisionally allocated room. Please wait until the general
                allotment list is announced. You can read the Revised Residence
                Allocation Policy{" "}
                <a
                  rel="noreferrer"
                  href={res?.dates?.Guidelines_URL__c}
                  target="_blank"
                >
                  here
                </a>{" "}
                and other FAQs{" "}
                <a
                  rel="noreferrer"
                  href={res?.dates?.Guidelines_URL__c}
                  target="_blank"
                >
                  here
                </a>
                .
              </Typography>,
            ];
            formData.info = info;
            formData.title = title;
            formData.button = null;
          }
          setCurrentFormStep(formData);
          // Steps
          setCurrentStep(res?.step);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [checkEligibility, updateState]);

  // GET Room Details API
  useEffect(() => {
    if (currentStep) {
      setLoading(true);
      getApplications(CONTACT_ID)
        .then((res) => {
          const data = [];
          const workinPreference = [...preferenceOptions];
          switch (currentStep) {
            case 1:
              res?.data?.forEach((items, index) => {
                const lists = items?.Description__c?.split(";") || [];
                const hasKeyword = (keyword) =>
                  lists.some((text) =>
                    text.toLowerCase().includes(keyword.toLowerCase())
                  );
                const icons = [];
                if (hasKeyword("Non-air conditioned")) {
                  icons.push("non-ac");
                }
                if (hasKeyword("Air-conditioned")) {
                  icons.push("ac");
                }
                if (hasKeyword("twin-sharing")) {
                  icons.push("twin-sharing");
                }
                if (hasKeyword("attached bathroom")) {
                  icons.push("attached-bathroom");
                }
                if (hasKeyword("Flat Style")) {
                  icons.push("flat-style");
                }
                if (hasKeyword("Standalone Room accommodation")) {
                  icons.push("single-room");
                }
                data.push({
                  ...items,
                  id: index,
                  Description__c: lists,
                  preference: "",
                  icons: icons,
                });
              });
              break;
            case 2:
              const preferenceData = [];
              const response = res?.data[0];
              // Getting the preference key data by key
              Object.keys(response).forEach((items) => {
                [
                  "Preference_1__c",
                  "Preference_2__c",
                  "Preference_3__c",
                ].forEach((keys) => {
                  if (items === keys) {
                    preferenceData.push(response[items]);
                  }
                });
              });
              // Getting the Card Data's by Key
              Object.keys(response).forEach((items) => {
                [
                  "Preference_1__r",
                  "Preference_2__r",
                  "Preference_3__r",
                ].forEach((keys, itemIndex) => {
                  if (items === keys) {
                    const lists =
                      response[items]?.Description__c?.split(";") || [];
                    const hasKeyword = (keyword) =>
                      lists.some((text) =>
                        text.toLowerCase().includes(keyword.toLowerCase())
                      );
                    const icons = [];
                    if (hasKeyword("Non-air conditioned")) {
                      icons.push("non-ac");
                    }
                    if (hasKeyword("Air-conditioned")) {
                      icons.push("ac");
                    }
                    if (hasKeyword("twin-sharing")) {
                      icons.push("twin-sharing");
                    }
                    if (hasKeyword("attached bathroom")) {
                      icons.push("attached-bathroom");
                    }
                    if (hasKeyword("Flat Style")) {
                      icons.push("flat-style");
                    }
                    if (hasKeyword("Standalone Room accommodation")) {
                      icons.push("single-room");
                    }
                    data.push({
                      ...response[items],
                      icons: icons,
                      id: itemIndex,
                      Description__c: lists,
                      preference: `Preference ${itemIndex + 1}`,
                      disabled: true,
                    });
                  }
                });
              });
              // Sorting (Optional only work for the key which have number in the last character)
              data.sort((a, b) => {
                const aIndex = parseInt(a.Name.substr(a.Name?.length - 1));
                const bIndex = parseInt(b.Name.substr(b.Name?.length - 1));
                if (typeof aIndex === "number" && typeof bIndex === "number") {
                  return aIndex - bIndex;
                }
              });
              setCurrentData({ ...res?.data[0], attachment: res?.attachment });
              break;
            case 3:
              if (paymentStatus) {
                setCurrentScreen({
                  // showBase: false,
                  showGeneralAllotment: true,
                });
              }
              break;
            case 4:
              setCurrentScreen({
                showBase: false,
                // showResidenceAllocationClosed: true,
                // showResidenceAllocation: true,
              });
              setPayStatus(true);
              break;
              // case 5:
              //   setCurrentScreen({
              //     showBase: false,
              //     showGeneralAllotment: true,
              //   });
              break;
            default:
              break;
          }
          // Main Card data
          setData(data);
          // Prefernce Dropdown Options
          setTermData(res?.myTerm);
          // setpreferenceOptions(workinPreference);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  }, [currentStep]);

  const handleChangePreference = (e, id) => {
    const workinData = [...data];
    workinData[id].preference = e.target.value;
    workinData?.forEach((el, index) => {
      if (id !== index && el.preference === e.target.value) {
        el.preference = "";
      }
    });
    setData([...workinData]);
  };

  // console.log(data);

  const resetPreference = () => {
    const workinData = [...preferenceOptions];
    workinData.forEach((items) => {
      items["disabled"] = false;
    });
    const maindata = [...data];
    maindata.forEach((items) => {
      items["preference"] = "";
    });
    setData(maindata);
    // setpreferenceOptions(workinData);
    setValue("preference1", null);
    setValue("preference2", null);
    setValue("preference3", null);
  };

  return (
    <>
      <FormProvider {...method}>
        {loading && <KenLoader />}
        <Grid
          container
          justifyContent="space-between"
          style={{
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "-4px",
          }}
          alignItems="center"
        >
          <Grid item>
            <Typography
              component={"a"}
              href="https://my.flame.edu.in/s/"
              style={{
                marginLeft: "20px",
                color: "#08466F",
                textDecoration: "none",
              }}
            >
              HOME
            </Typography>
          </Grid>
          <Grid item>
            <img
              src={logo}
              height={"80px"}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => {
                localStorage.clear();
                history.push("/");
              }}
            />
          </Grid>
          {!isMobileScreen && <Grid item />}
        </Grid>
        <Box marginTop={1}>
          <Grid
            container
            xs={12}
            style={{
              minHeight: "85vh",
              maxHeight: "max-content",
              padding: isMobileScreen ? "10px 20px" : "24px",
            }}
          >
            {!isMobileScreen ? (
              <>
                {currentFormStep &&
                currentStep &&
                currentScreen.showBase &&
                haveData === false  ? (
                  <Grid xs={12} className={classes.imageBackground}>
                    <Grid
                      item
                      component="main"
                      xs={7}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "85vh",
                      }}
                    >
                      <Paper
                        className={classes.box}
                        style={{ padding: "30px" }}
                      >
                        <Grid
                          container
                          style={{ height: "100%", display: "flex" }}
                        >
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: currentStep === 2 ? "20px" : "40px",
                            }}
                          >
                            <Typography variant="p" className={classes.header}>
                              {currentFormStep.title}
                            </Typography>
                            {currentFormStep.points ? (
                              <div style={{ textAlign: "left" }}>
                                <Typography
                                  style={{
                                    fontWeight: 600,
                                    color: "#222222",
                                    fontSize: "16px",
                                  }}
                                >
                                  Next Steps:
                                </Typography>
                                <Stepper
                                  activeStep={3}
                                  orientation="vertical"
                                  style={{ padding: "10px" }}
                                >
                                  {currentFormStep.info.map((step, index) => {
                                    return (
                                      <Step
                                        key={index}
                                        className={classes.stepperList}
                                      >
                                        <StepLabel>
                                          <Typography
                                            className={classes.stepperLabel}
                                          >
                                            {step}
                                          </Typography>
                                        </StepLabel>
                                      </Step>
                                    );
                                  })}
                                </Stepper>
                              </div>
                            ) : (
                              <Grid
                                container
                                xs={12}
                                spacing={2}
                                style={{ textAlign: "left" }}
                              >
                                {currentFormStep.info.map((text, index) => {
                                  return (
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="p"
                                        className={classes.description}
                                      >
                                        {text}
                                      </Typography>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            )}
                            {currentFormStep.subText &&
                              currentFormStep.subText.map((text, index) => {
                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "left" }}
                                  >
                                    <Typography
                                      variant="p"
                                      className={classes.description}
                                    >
                                      {text}
                                    </Typography>
                                  </Grid>
                                );
                              })}

                            {currentFormStep.show && (
                              <Typography
                                variant="p"
                                className={classes.deadline}
                              >
                                {currentFormStep.deadline
                                  ? "Deadline"
                                  : "Announcement"}
                                : {currentFormStep.date}
                              </Typography>
                            )}
                          </Grid>
                          {currentFormStep.button && (
                            <Grid item xs={12} style={{ marginTop: "30px" }}>
                              <Button
                                color="primary"
                                variant="contained"
                                className={classes.formButton}
                                onClick={() => handleShowScreen()}
                              >
                                {currentFormStep.button}
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                ) : null}
              </>
            ) : (
              <>
                {currentFormStep &&
                currentStep &&
                currentScreen.showBase &&
                haveData === false ? (
                  <Grid xs={12} className={classes.imageBackground}>
                    <Grid
                      item
                      component="main"
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "85vh",
                        padding: "20px",
                      }}
                    >
                      <Paper
                        className={classes.box}
                        style={{ padding: "20px" }}
                      >
                        <Grid
                          container
                          style={{ height: "100%", display: "flex" }}
                        >
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "20px",
                            }}
                          >
                            <Typography variant="p" className={classes.header}>
                              {currentFormStep.title}
                            </Typography>
                            {currentFormStep.points ? (
                              <div style={{ textAlign: "left" }}>
                                <Typography
                                  style={{
                                    fontWeight: 600,
                                    color: "#222222",
                                    fontSize: "16px",
                                  }}
                                >
                                  Next Steps:
                                </Typography>
                                <Stepper activeStep={3} orientation="vertical">
                                  {currentFormStep.info.map((step, index) => {
                                    return (
                                      <Step
                                        key={index}
                                        className={classes.stepperList}
                                      >
                                        <StepLabel>
                                          <Typography
                                            className={classes.stepperLabel}
                                          >
                                            {step}
                                          </Typography>
                                        </StepLabel>
                                      </Step>
                                    );
                                  })}
                                </Stepper>
                              </div>
                            ) : (
                              <Grid
                                container
                                xs={12}
                                style={{ textAlign: "left", gap: "16px" }}
                              >
                                {currentFormStep.info.map((text, index) => {
                                  return (
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="p"
                                        className={classes.description}
                                      >
                                        {text}
                                      </Typography>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            )}
                            {currentFormStep.subText &&
                              currentFormStep.subText.map((text, index) => {
                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "left" }}
                                  >
                                    <Typography
                                      variant="p"
                                      className={classes.description}
                                    >
                                      {text}
                                    </Typography>
                                  </Grid>
                                );
                              })}

                            {currentFormStep.show && (
                              <Typography
                                variant="p"
                                className={classes.deadline}
                              >
                                {currentFormStep.deadline
                                  ? `Deadline`
                                  : "Announcement"}
                                : {currentFormStep.date}
                              </Typography>
                            )}
                          </Grid>
                          {currentFormStep.button && (
                            <Grid item xs={12} style={{ marginTop: "40px" }}>
                              <Button
                                color="primary"
                                variant="contained"
                                className={classes.formButton}
                                style={{ width: "100%" }}
                                onClick={() => handleShowScreen()}
                              >
                                {currentFormStep.button}
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                ) : null}
              </>
            )}

            {checkEligibility === false && (
              <Grid xs={12} className={classes.imageBackground}>
                <Grid
                  item
                  component="main"
                  xs={8}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "85vh",
                  }}
                >
                  <Paper className={classes.box} style={{ padding: "30px" }}>
                    <Grid container style={{ height: "100%", display: "flex" }}>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: currentStep === 2 ? "20px" : "40px",
                        }}
                      >
                        <Typography variant="p" className={classes.header}>
                          MyResidences allows students to submit their residence
                          type preferences and get updates on the residence type
                          allocation status for the upcoming academic year
                          2024-2025.
                        </Typography>
                        <Typography variant="p" className={classes.header}>
                          MyResidences is therefore not accessible for students
                          graduating in year 2024.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            )}

            {/* Step 1 */}
            {currentScreen.showRequestApplication && (
              <RequestApplication
                handleChangePreference={handleChangePreference}
                currentData={currentData}
                resetPreference={resetPreference}
                preferenceOptions={preferenceOptions}
                data={data}
                termData={termData}
                handleClose={handleClose}
                handleStepComplete={handleStepComplete}
                currentFormStep={currentFormStep}
                user={user}
              />
            )}
            {/* Step 2 */}
            {currentScreen.showProvisionalAllotment && (
              <ProvisionalAllotment
                handleClose={handleClose2}
                handlePaymentComplete={handleClosePay}
                currentFormStep={currentFormStep}
                generalAllotment={false}
                user={user}
                setHaveData={setHaveData}
              />
            )}
            {/* Step 3 - Only when payment is complete */}
            {showHistory && (
              <ResidenceAllocationHistory
                currentFormStep={currentFormStep}
                isGeneralAllotmentBefore={isGeneralAllotmentBefore}
                user={user}
              />
            )}
            {/* Step 4 */}
            {/* {currentScreen.showResidenceAllocationClosed && <ResidenceAllocationClosed currentData={currentData} preferenceOptions={preferenceOptions} data={data} handleClose={handleClose3} currentFormStep={currentFormStep} user={user} />} */}
            {/* Step 5 */}
            {showPending && (
              <ProvisionalAllotment
                handleClose={handleClose2}
                handlePaymentComplete={handleClosePay}
                currentFormStep={currentFormStep}
                generalAllotment={true}
                user={user}
                setHaveData={setHaveData}
              />
            )}
          </Grid>
        </Box>
      </FormProvider>
    </>
  );
};

export default ResidenceAllocation;
