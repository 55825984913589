import React from "react";
import ResidenceAllocation from "../Residence-Allocation";
import { useAppContext } from "../../utils/contextProvider/AppContext";
import { getAxiosInstance, getConfig } from "../../utils/ApiService";
import KenLoader from "../../components/KenLoader";

export default function MainLayout() {
  const [config, setConfig] = React.useState();
  const { state, dispatch } = useAppContext();

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  React.useEffect(() => {
    getConfig()
      .then((res) => {
        dispatch({ type: "updateConfig", value: res[0]?.config });
        getAxiosInstance(res);
        setConfig(res[0].config);
      })
      .catch((err) => {
        console.log("error in", err);
        // setLoading(false);
      });
  }, []);

  return <ResidenceAllocation user={userDetails} />;
}
