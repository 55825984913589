import axios from "axios";
import { KEY_ACCESS_TOKEN, KEY_CONFIG_URL, KEY_LOGIN_ACCESS_TOKEN, KEY_REFRESH_TOKEN, REFRESH_URL } from "./constants";
import * as Sentry from "@sentry/react";

const baseUrl = "https://flame-prod-api-az.ken42.com/pfs-integration/api";
let appConfig;
export let axiosInstance;
const configUrl = KEY_CONFIG_URL;
let integrationInstance;

export const getIntegrationInstance = async () => {
  if (integrationInstance) {
    return integrationInstance;
  }
  integrationInstance = axios.create({
    baseURL: `${appConfig.bbbmiddleware}`,
    timeout: 15000,
  });

  integrationInstance.interceptors.request.use((req) => {
    const token = localStorage.getItem(KEY_ACCESS_TOKEN);
    req.headers.Authorization = `Bearer ${token}`;
    req.headers["access-token"] = localStorage.getItem(KEY_LOGIN_ACCESS_TOKEN);
    return req;
  });

  //Response interceptor for API calls
  integrationInstance.interceptors.response.use(
    (resp) => {
      return resp;
    },
    async function (error) {
      Sentry.captureException(error);
      var config = {
        method: "get",
        baseURL: `${appConfig.tokenUrl}`,
      };
      return axios(config).then(function (response) {
        localStorage.setItem(KEY_ACCESS_TOKEN, response?.data?.access_token);
        error.response.config.headers["Authorization"] = `Bearer ${response?.data?.access_token}`;
        return axios(error.response.config);
      });
    }
  );
  return integrationInstance;
};

export const getAxiosInstance = async (data) => {
  appConfig = data[0]?.config;
  if (axiosInstance) {
    return axiosInstance;
  }
  axiosInstance = axios.create({
    baseURL: `${appConfig.apiBaseUrl}`,
    timeout: 15000,
  });

  axiosInstance.interceptors.request.use((req) => {
    const token = localStorage.getItem(KEY_ACCESS_TOKEN);
    req.headers["access-token"] = localStorage.getItem(KEY_LOGIN_ACCESS_TOKEN);
    req.headers.Authorization = `Bearer ${token}`;
    return req;
  });

  //Response interceptor for API calls
  axiosInstance.interceptors.response.use(
    (resp) => {
      return resp;
    },
    async function (error) {
      const url = REFRESH_URL;
      let access_token = localStorage.getItem("access_token");
      Sentry.captureException(error);
      const instance = await getIntegrationInstance();
      try {
        const res = await instance.post(
          url,
          {
            refreshToken: localStorage.refresh_token,
          },
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        localStorage.setItem(KEY_LOGIN_ACCESS_TOKEN, res?.data?.accessToken?.token);
        localStorage.setItem(KEY_REFRESH_TOKEN, res?.data?.refreshToken?.token);
      } catch (err) {
        console.error(err);
      }
      if (!error.response?.config && error.response?.status !== 401) {
        return error;
      }
      var config = {
        method: "get",
        baseURL: `${appConfig.tokenUrl}`,
      };
      return axios(config).then(function (response) {
        localStorage.setItem(KEY_ACCESS_TOKEN, response.data.access_token);
        error.response.config.headers["Authorization"] = `Bearer ${response.data.access_token}`;
        return axios(error.response.config);
      });
    }
  );
};

export const getConfig = async () => {
  const path = "myexams.flame.edu.in";
  const res = await axios.get(`${configUrl}/${path}`);
  return res && res.data ? res.data : null;
};

// Residence
export const getCurrentStage = async (contact) => {
  const path = `sf/residence/getCurrentStage/${contact}`;
  const res = await axios.get(`${baseUrl}/${path}`);
  return res && res.data ? res.data : null;
};

export const getApplications = async (contact) => {
  const path = `sf/residence/getRoomDetails/${contact}`;
  const res = await axios.get(`${baseUrl}/${path}`);
  return res && res.data ? res.data : null;
};

export const getResidenceHistory = async (contact) => {
  const path = `sf/residence/getResidentHistory/${contact}`;
  const res = await axios.get(`${baseUrl}/${path}`);
  return res && res.data ? res.data : null;
};

export const getProvisionalAllotmentDetails = async (contact) => {
  const path = `sf/residence/getProvisionalAllotmentDetails/${contact}`;
  const res = await axios.get(`${baseUrl}/${path}`);
  return res && res.data ? res.data : null;
};

export const postRoomRequest = async (payload) => {
  const path = `sf/residence/postRoomRequest`;
  const res = await axios.post(`${baseUrl}/${path}`, payload);
  return res && res.data ? res.data : null;
};

export const getAllRoomDetails = async (contact) => {
  const path = `sf/residence/getAllRoomDetails/${contact}`;
  const res = await axios.get(`${baseUrl}/${path}`);
  return res && res.data ? res.data : null;
};

export const getEligibilityDetails = async (contact) => {
  const path = `sf/residence/getEligibilityDetails/${contact}`;
  const res = await axios.get(`${baseUrl}/${path}`);
  return res && res.data ? res.data : null;
};

export const hdfcPaymentIntegartion = async (data) => {
  const path = `${baseUrl}/hdfc/payments/initiate`;
  const res = await axios.post(path, data);
  return res;
};

export const getFeeReceipt = async (transactionId) => {
  const path = `${baseUrl}/salesforce?endpoint=${encodeURIComponent(`/services/apexrest/portalfee/retrieve/receipt?transactionid=${transactionId}`)}`;
  const res = await axios.get(path);
  return res && res.data ? res.data : null;
};
