import React from "react";
import { Grid, Typography, makeStyles, useMediaQuery, useTheme, Divider } from "@material-ui/core";
import { TABLET_BREAKPOINT } from "../../../../../utils/constants";
import KenGrid from "../../../../../global_components/KenGrid";
import moment from "moment";
import { saveAs } from "file-saver";
import { getFeeReceipt } from "../../../../../utils/ApiService";

const useStyles = makeStyles((theme) => ({
  subHeadingFeeType: {
    color: theme.palette.KenColors.grey3,
    fontSize: 12,
    fontWeight: 500,
  },
  subHeadingModeSuccess: {
    fontStyle: "italic",
    color: theme.palette.KenColors.tertiaryGreen504,
    fontSize: 12,
  },
  subHeadingModeFail: {
    fontStyle: "italic",
    color: theme.palette.KenColors.statusRed,
    fontSize: 12,
  },
  subHeadingModeInitiated: {
    fontStyle: "italic",
    color: "orange",
    fontSize: 12,
  },
  typoPaymentTypePartialSuccess: {
    fontSize: 12,
    color: theme.palette.KenColors.kenWhite,
    background: theme.palette.KenColors.statusGreen,
    borderRadius: 4,
  },
  typoPaymentTypePartialFailed: {
    fontSize: 12,
    color: theme.palette.KenColors.kenWhite,
    background: theme.palette.KenColors.statusRed,
    borderRadius: 4,
  },
  typoPaymentTypeFullSuccess: {
    fontSize: 12,
    color: theme.palette.KenColors.kenWhite,
    background: theme.palette.KenColors.statusGreen,
    borderRadius: 4,
  },
  typoPaymentTypeFullFailed: {
    fontSize: 12,
    color: theme.palette.KenColors.kenWhite,
    background: theme.palette.KenColors.statusRed,
    borderRadius: 4,
  },
  subHeadingPaymentTypeSuccess: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.KenColors.grey3,
  },
  statusBadge: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      maxHeight: "18px",
      display: "flex",
      "flex-direction": "row",
      "justify-content": "center",
      "align-items": "center",
      padding: "8px",
      gap: "10px",
      background: "#F6C445",
      "border-radius": "4px",
      width: "fit-content",

      "font-style": "normal;",
      "font-weight": "500;",
      "font-size": "12px;",
      "line-height": "100%;",
      "text-transform": "capitalize;",
      color: "#FFFFFF;",
    },
  },
  rootContainer: {
    borderRadius: 8,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      borderRadius: 0,
      border: 0,
    },
  },
  accordionDetail: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: 0,
    },
  },
  card: {
    background: "#F7F8FB",
    "border-radius": "12px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  summary: {
    paddingTop: 0,
    "& > .MuiAccordionSummary-content": {
      marginTop: `${theme.spacing(1.5)} 0px !important!important`,
    },
  },
  date: {
    "font-style": "normal",
    "font-weight": "400",
    "font-size": "10px",
    "line-height": "100%",
    "text-align": "right",
    "text-transform": "capitalize",
    color: theme.palette.KenColors.grey2,
  },
  subItemTitle: {
    "font-style": "normal",
    "font-weight": "500",
    "font-size": "14px",
    "line-height": "100%",
    color: theme.palette.KenColors.grey3,
  },
  transactionIdValue: {
    "font-style": "normal",
    "font-weight": "500",
    "font-size": "14px",
    "line-height": "100%",
    color: theme.palette.KenColors.grey2,
    marginTop: theme.spacing(0.5),
  },
  transactionIdLabel: {
    "font-style": "normal",
    "font-weight": "500",
    "font-size": "10px",
    "line-height": "100%",
    color: theme.palette.KenColors.grey3,
  },
  download: {
    fontWeight: 500,
    color: theme.palette.KenColors.vibrantBlue,
    textDecorationLine: "underline",
    marginRight: 16,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: 0,
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const RenderTransactionCards = ({ data, handleDownloadReceipt }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid container xs={12}>
      {data &&
        data?.map((item, index) => {
          return (
            <Grid item={index} container p={theme.spacing(2)} flexDirection="column" className={classes.card}>
              <Grid item>
                <>
                  <Divider />
                  <Grid container p={theme.spacing(1.5)}>
                    <Grid item container alignItems="center" pb={theme.spacing(1)}>
                      <Grid item xs={6}>
                        <Typography fontWeight={600}>Date:</Typography>
                        <Typography variant="h3" fontWeight={500} className={classes.subItemTitle}>
                          {moment(item?.transactionDate).format("DD-MM-YYYY")} {moment(item?.transactionDate).format("hh:mm")}
                        </Typography>
                      </Grid>
                      <Grid xs={6} item container flexDirection="column" textAlign="right">
                        <Typography fontWeight={600}>Amount:</Typography>
                        <Typography variant="h3" fontWeight={500} className={classes.subItemTitle}>
                          {item?.feeAmount}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={6} pb={theme.spacing(1)} alignItems="right">
                        <Typography fontWeight={600}>Mode:</Typography>
                        <Typography variant="p" fontWeight={500}>
                          {item.mode}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} pb={theme.spacing(1)} textAlign="right">
                        <Typography fontWeight={600}>Status:</Typography>
                        <Typography fontWeight={500} style={{ marginRight: 4 }} className={item?.webhookStatus === "Success" ? classes.subHeadingModeSuccess : classes.subHeadingModeFail}>
                          {item?.webhookStatus}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} pb={theme.spacing(1)}>
                        <Typography fontWeight={600}>Reconciliation Status:</Typography>
                        <Typography variant="p" fontWeight={500} textAlign="left" className={item?.reconciliationStatus === "Success" ? classes.subHeadingModeSuccess : classes.subHeadingModeFail}>
                          {item?.reconciliationStatus}
                        </Typography>
                      </Grid>
                      {item?.webhookStatus === "guaranteed" ? (
                        <Grid item xs={6}>
                          <Typography
                            variant="subtitle2"
                            className={classes.download}
                            onClick={() => {
                              handleDownloadReceipt(item?.recordId);
                            }}
                          >
                            Download Receipt
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default function TransactionsHistory({ data }) {
  // conso
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(TABLET_BREAKPOINT));

  const headerCell = (value) => {
    return (
      <Typography variant="p" fontWeight={500}>
        {value}
      </Typography>
    );
  };

  const convertBase64ToFile = (base64String, fileName) => {
    const bytes = atob(base64String);
    let n = bytes.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bytes.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: "application/pdf" });
    return file;
  };

  const handleDownloadReceipt = (transactionId) => {
    getFeeReceipt(transactionId).then((res) => {
      const file = convertBase64ToFile(res?.data?.body, res?.data?.title);
      saveAs(file, res?.data?.title);
    });
  };

  const getFeeStatus = (status) => {
    switch (status) {
      case "initiated":
        return "Initiated, Not Completed";
      case "guaranteed":
        return "Paid";
      case "delivered":
        return "Payment Recieved";
      case "cancelled":
        return "Cancelled";
      case "processed":
        return "Paid";
      case "failed":
        return "Failed";
      default:
        return "";
    }
  };

  const columns = [
    {
      Header: headerCell("Date"),
      accessor: "transactionDate",
      Cell: ({ row, value }) => {
        return (
          <Grid container direction="column" spacing={1}>
            <Grid item>
              {value && (
                <Typography variant="p" fontWeight={500}>
                  {moment(value).format("DD-MM-YYYY")}
                </Typography>
              )}
            </Grid>
            <Grid item>
              {value && (
                <Typography variant="p" fontWeight={500} className={classes.subHeadingFeeType}>
                  {moment(value).format("hh:mm A")}
                </Typography>
              )}
            </Grid>
          </Grid>
        );
      },
    },
    {
      Header: headerCell("Transaction ID"),
      accessor: "transactionId",
      Cell: ({ row, value }) => {
        return (
          <Typography variant="p" fontWeight={500}>
            <span style={{ fontSize: 10 }}>{row.original.currencyCode}</span> {value}
          </Typography>
        );
      },
    },
    {
      Header: headerCell("Amount (Additional Lodging Charges)"),
      accessor: "feeAmount",
      Cell: ({ row, value }) => {
        return (
          <Typography variant="p" fontWeight={500}>
            <span style={{ fontSize: 10 }}>{row.original.currencyCode}</span> {value}
          </Typography>
        );
      },
    },
    {
      Header: headerCell("Mode"),
      accessor: "mode",
      Cell: ({ row, value }) => {
        return (
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="p" fontWeight={500}>
                {value}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      Header: headerCell("Status"),
      accessor: "webhookStatus",
      Cell: ({ row, value }) => {
        return <Typography className={row?.original?.webhookStatus === "guaranteed" ? classes.subHeadingModeSuccess : row?.original?.webhookStatus === "initiated" ? classes.subHeadingModeInitiated : classes.subHeadingModeFail}>{getFeeStatus(row?.original?.webhookStatus)}</Typography>;
      },
    },

    {
      Header: headerCell("Receipt"),
      accessor: "recordId",
      Cell: ({ row, value }) => {
        return (
          <Grid container direction="column" spacing={1} width={100}>
            <Grid item>
              {value && row?.original?.webhookStatus === "guaranteed" && (
                <Typography
                  variant="subtitle2"
                  className={classes.download}
                  onClick={() => {
                    handleDownloadReceipt(value);
                  }}
                >
                  Download Receipt
                </Typography>
              )}
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <div className={classes.rootContainer}>
      {isMobileScreen ? (
        <RenderTransactionCards data={data} handleDownloadReceipt={handleDownloadReceipt} />
      ) : (
        <KenGrid
          toolbarDisabled={true}
          columns={columns}
          data={data}
          pagination={{ disabled: true }}
          initialState={{ hiddenColumns: ["status"] }}
          gridProps={{
            getHeaderGroupProps: (column) => ({
              style: { border: "1px solid #D2E1E9" },
            }),
            getHeaderProps: (column) => ({
              style: {
                background: "#E0E0E0",
                color: "#000",
                textAlign: "center",
                fontSize: 14,
                padding: "10px"
              },
            }),
            getColumnProps: (column) => ({
              style: {
                background: "#fff",
                textAlign: "center",
                color: "#000",
              },
            }),
          }}
        />
      )}
    </div>
  );
}
