import React from 'react';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

const StyledBox = styled(Box)(theme => ({
  color: '#FC2C32',
}));

export default function KenError({ errors, field, type, message, single }) {
  if (single && message) {
    return (
      <StyledBox component="span" textAlign={'start'}>
        <Typography fontSize={'12px'} marginTop={'4px'} textAlign={'start'}>
          {message || 'Please enter valid input'}
        </Typography>
      </StyledBox>
    );
  }

  return (
    <StyledBox component="span">
      <Typography>
        {errors[field] && errors[field]?.type === type ? message : ''}
      </Typography>
    </StyledBox>
  );
}
