import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Grid,
  Typography,
  makeStyles,
  Container,
  Paper,
  Switch,
  FormGroup,
  FormControlLabel,
  Button,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Controller, useForm } from 'react-hook-form';
import { REGEX, TABLET_BREAKPOINT } from '../../../../../utils/constants';
import KenError from '../../../../../components/KenError';
import KenTextLabel from '../../../../../global_components/KenTextLabel';
import KenInput from '../../../../../global_components/KenInputField';
import KenButton from '../../../../../global_components/KenButton';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  btnLabel: {
    fontSize: '14px',
  },
}));

export const DirectPay = props => {
  const {
    transactionData,
    setTransactionData,
    handleCloseDirect,
    onSubmit,
  } = props;
  const [base64String, setBase64String] = useState('');
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleConfirm = data => {
    // Any Data Modification done here before submit
    onSubmit(data);
  };

  function handleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function() {
        const base64String = reader.result.split(',')[1]; // Extract the Base64 data part
        setBase64String(base64String);
      };
      reader.onerror = function() {
        console.error('Error reading the file');
      };
      reader.readAsDataURL(file);
    }
  }

  return (
    <form onSubmit={handleSubmit(handleConfirm)}>
      <ArrowBackIosIcon
        onClick={handleCloseDirect}
        style={{ float: 'left', cursor: 'pointer', marginBottom: '10px' }}
      />
      <Grid container spacing={2} xs={12} md={12} mt={3}>
        <Grid item xs={12} md={6}>
          <Controller
            name="transactionId"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <KenInput
                  error={error?.message}
                  required={true}
                  label="Transaction ID"
                  value={value}
                  onChange={onChange}
                />
              );
            }}
            {...register('transactionId', {
              required: 'Field is required',
              pattern: {
                value: REGEX.ALPHANUMBERIC,
                message: 'Please Enter valid transaction ID',
              },
              minLength: {
                value: 10,
                message: 'This Field length cannot be less than 10 characters',
              },
              maxLength: {
                value: 30,
                message: 'This Field length cannot be more than 30 characters',
              },
              required: 'Field is required',
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="transactionDate"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <KenInput
                  required={true}
                  label="Transaction Date"
                  value={value}
                  error={error?.message}
                  type="date"
                  maxDate={true}
                  onChange={onChange}
                />
              );
            }}
            {...register('transactionDate', {
              required: 'Field is required',
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="amount"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <KenInput
                  required={true}
                  label="Transaction Amount"
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
            {...register('amount', {
              required: 'Field is required',
              pattern: {
                value: REGEX.DECIMAL,
                message: 'Please enter a valid amount value',
              },
              maxLength: {
                value: 8,
                message: 'This field length cannot be more than 7',
              },
              min: { value: 1, message: 'Value should be greater than 1' },
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <>
            <KenTextLabel required={true} label="Upload Proof of Transaction" />
            <div
              style={{
                backgroundColor: '#F3F5F7',
                padding: '20px 10px',
                borderRadius: 4,
              }}
            >
              <input
                type="file"
                multiple={false}
                accept="image/png, application/pdf"
                {...register('fileName', {
                  required: 'Field is required',
                  onChange: e => {
                    handleFileChange(e);
                    const size =
                      e.target.files?.length > 0
                        ? e.target.files[0]?.size > 2000000
                        : false;
                    if (size) {
                      enqueueSnackbar('File size limit only 2mb', {
                        variant: 'error',
                      });
                    } else {
                      setTransactionData({
                        ...transactionData,
                        fileName:
                          e.target.value === '' ? null : e.target.files[0],
                      });
                    }
                  },
                })}
              />
            </div>
            {errors['fileName'] && (
              <KenError single={true} message={errors['fileName']?.message} />
            )}
          </>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '20px',
        }}
      >
        <KenButton
          color="primary"
          variant="secondary"
          type="button"
          label={
            <Typography className={classes.btnLabel}>{'Go Back'}</Typography>
          }
        />
        <KenButton
          className={classes.btnLabel}
          color="primary"
          variant="primary"
          type="submit"
          label={
            <Typography className={classes.btnLabel}>{'Confirm'}</Typography>
          }
        />
      </Box>
    </form>
  );
};
export default DirectPay;
