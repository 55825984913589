import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Card,
  Grid,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const directPayDetails = {
  'Account Name': 'FLAME University Pune',
  'Bank & Branch': 'Bank of India, Lavale',
  'A/c No': '062020110000581',
  'SWIFT Code': 'BKIDINBBPMB',
  'IFSC Code': 'BKID0000620',
};

const PayMethod = ({
  onGoBack,
  selectedPanel,
  setSelectedPanel,
  handleRadioChange,
}) => {
  const [expanded, setExpanded] = useState('');

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
    setSelectedPanel(panel);
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <Card style={{ padding: '20px' }}>
        <Typography style={{ display: 'flex' }}>
          Choose the Method for Payment
        </Typography>
        <Box
          style={{
            padding: '10px 16px',
            borderRadius: '4px',
            transition:
              'min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            borderBottom: '2px solid rgba(0, 0, 0, 0.2)',
          }}
          onChange={handleChange('panel1')}
        >
          <RadioGroup
            aria-label="panel1"
            name="panel1"
            value={selectedPanel}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="panel1"
              control={<Radio />}
              label="Credit/Debit/UPI"
            />
          </RadioGroup>
        </Box>
        <Accordion
          style={{ marginTop: '10px' }}
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <RadioGroup
              aria-label="panel2"
              name="panel2"
              value={selectedPanel}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="panel2"
                control={<Radio />}
                label="Direct Net-Banking"
              />
            </RadioGroup>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container style={{ marginLeft: '25px' }}>
              {Object.entries(directPayDetails).map(([key, value]) => {
                return (
                  <Grid container>
                    <Grid item md={3}>
                      <Typography style={{ fontSize: '14px' }}>
                        {key}
                      </Typography>
                    </Grid>
                    <Grid item md={1}>
                      <Typography style={{ fontSize: '14px' }}>:</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography style={{ fontSize: '14px' }}>
                        {value}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Typography
              style={{ fontSize: '14px', fontWeight: 600, marginTop: '10px' }}
            >
              Note : Please click on 'Confirm' only once you have made the
              payment.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Card>
    </div>
  );
};

export default PayMethod;
