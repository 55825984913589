import React, { useState } from 'react';
import {
  CircularProgress,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import download from '../../assets/download.svg';
import { useSnackbar } from 'notistack';
import KenError from '../KenError';

const useStyles = makeStyles(theme => ({
  kenDocUpload: {
    border: '0.50px #E4E4E4 solid',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '8px',
    padding: 4,
  },
  chooseFile: {
    background: '#E1E6EF',
    marginLeft: 'auto',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    borderRadius: '6px',
    justifyContent: 'center',
  },
  fileName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '16px',
    lineHeight: '18px',
    maxWidth: '250px',
    margin: '10px',
  },
  downloadField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '0.50px #e4e4e4 solid',
    padding: '4px',
    textDecoration: 'none',
    borderRadius: '8px',
  },
}));

const DocumentUpload = (props) => {
  const { acceptedType, width, disabled, attachments } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { register, setValue, formState: { errors } } = useFormContext();

  const [uploading, setUploading] = useState(false);
  const [fileName, setFileName] = useState('');

  const handleFileChange = (e) => {
    setUploading(true);
    const hasFiles = e.target.files.length > 0 ? e.target.files[0] : false;

    if (hasFiles && hasFiles.size > 2000000) {
      enqueueSnackbar('Document size is greater than 2MB. Please choose a smaller file.', {
        variant: 'error',
      });
      setFileName('');
    } else if (hasFiles && hasFiles.type === 'application/pdf') {
      setFileName(hasFiles.name);
      setValue('attachment', hasFiles); // Set the file value in the form context
    } else {
      const acceptPdfFiles = hasFiles?.type;
      if (acceptPdfFiles) {
        setFileName(hasFiles?.name);
        setValue('attachment', hasFiles);
      } else {
        enqueueSnackbar('Only PDF and Image file are accepted', {
          variant: 'error',
        });
      }
    }

    setUploading(false);
  };

  return (
    <>
      {attachments?.done ? (
        <a
          href={attachments?.records[0]?.DistributionPublicUrl}
          target="_blank"
          download={attachments?.records[0]?.Name}
          className={classes.downloadField}
        >
          <div className={classes.fileName}>
            {attachments?.records[0]?.Name}
          </div>
          <img
            src={download}
            alt="download"
            style={{ height: '20px', width: 'auto' }}
          />
        </a>
      ) : (
        <Grid
          container
          alignItems="left"
          direction="column"
          style={{ width: width }}
          className={classes.kenDocUpload}
        >
          <input
            accept={acceptedType}
            style={{ display: 'none' }}
            disabled={disabled}
            id="contained-button-file"
            multiple
            type="file"
            {...register('attachment')}
            onChange={(e) => handleFileChange(e)}
          />
          <label
            htmlFor="contained-button-file"
            style={{
              lineHeight: '1em',
              width: '100%',
              display: 'flex',
              cursor: disabled ? 'none' : 'pointer',
            }}
          >
            <div
              className={classes.fileName}
              variant="contained"
              color="primary"
              component="span"
            >
              {fileName || 'Upload'}
            </div>
            <span className={classes.chooseFile}>Choose file</span>
          </label>
          {uploading && (
            <CircularProgress size={68} style={{ margin: '20px auto' }} />
          )}
        </Grid>
      )}
      {errors['attachment'] && (
        <KenError single={true} message={errors['attachment'].message} />
      )}
    </>
  );
};

export default DocumentUpload;
